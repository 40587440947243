import { graphql } from "gatsby"
import * as React from 'react'
import { Col, Container, Row } from "react-bootstrap"
import Layout from '../components/layout/layout'
import Seo from '../components/seo/seo'
import VideoHero from '../components/videobackground/videobackground'
import { StaticImage } from "gatsby-plugin-image"
import ButtonDivWidget from "../components/buttonDivWidget/buttonDivWidget"


function AboutPage( {data} ) {
    return (
        <Layout>
            <Seo title="Home" />
            <VideoHero />
            <div className="pageHeading">
                <h1>About Sellcius Networks</h1>
            </div>
            <Row className="background-blackPurple">
                <Container>
                    <Row>
                        <Col className="imageBackground" id="networkingPeople">
                        </Col>
                        <Col className="section-verticalAlignMiddle">
                            <h2 className="section-title">We know our boundaries</h2>
                            <p className="paragraph">
                                Sellcius Networks LLC was founded in late 2019 as part of a larger vision.
                                A vision that includes connecting real people with real data. While our team is small,
                                it gives us the clarity and the freedom to select only the projects we can support
                                and do masterfully. Where we fall short, we admit and own it. But, let's be honest: we 
                                are pretty awesome!
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row className="background-black">
                <Container>
                    <Row>
                        <Col className="section-verticalAlignMiddle">
                            <h2 className="section-title">We believe in data-first</h2>
                            <p className="paragraph">
                                Data drives everything we do at Sellcius Networks. 
                                <br /><br />
                                We breath life into your data to take it from inanimate information into living 
                                narratives about your customers journies, behaviors... their's ups and downs, frustrations,
                                and joys with your websites, products, and marketing and advertising efforts -- all with
                                the rich data you likely already have!
                            </p>
                        </Col>
                        <Col className="imageBackground" id="dataFirst">
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row className="background-purpleBlack">
                <Container>
                    <Row>
                        <Col className="imageBackground" id="trustImage">
                        </Col>
                        <Col className="section-verticalAlignMiddle">
                            <h2 className="section-title">Brands trust us, soups-to-nuts</h2>
                            <p className="paragraph">
                                Brands such as <a href="www.rvsnag.com">RVSnag</a> use our insights and innovations and technology to drive
                                sales, marketing, and brand awareness of countless individual sellers, vendors, manufacturers, and 
                                dealerships. 
                                <br />
                                <br />
                                And, you can too!
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row className="background-black">
                <Container>
                    <Row>
                        <Col className="section-verticalAlignMiddle">
                            <h2 className="section-title">The perfect recipe to balance your martech solutions</h2>
                            <p className="paragraph">
                                We rely on one-part data, two-parts development, and five-parts innerconnectivity.
                                Your phone, laptop, watch, treadmill, car, TV, and various other IoT devices are digitally married 
                                when Sellcius Networks connects it to your website, apps, and API's.
                            </p>
                        </Col>
                        <Col className="imageBackground" id="balanceImage">
                            
                        </Col>
                    </Row>
                </Container>
            </Row>
            <ButtonDivWidget cta="Ready to get started?" text="Get a Quote Today" />
        </Layout>
    )
}
export default AboutPage


// export const pagesQuery = graphql`
//     query {
//         file(relativePath: { eq: "networkPeople.png" }) {
//         childImageSharp {
//             # Specify a fixed image and fragment.
//             # The default width is 400 pixels
//             fluid {
//                 ...GatsbyImageSharpFluid
//             }
//         }
//         }
//     }
// `